import {
  audits,
  carrierAccounts,
  locations,
} from '@cimpress-technology/logistics-configuration-client'

export interface Location
  extends locations.models.LocationWithCalendars,
    Partial<locations.models.LocationLinks> {
  fulfillmentLocations: FulfillmentLocation[]
  editable: boolean
  tagsEnabled: boolean
  showDeliveryCalendar: boolean
  networkIsAccessible?: boolean | undefined
}
export type LightLocation = Omit<Location, 'countryCalendar'>

export interface LocationAddress {
  countryCode: string
  locality: string
  region?: string
  postalCode?: string
  street1?: string
  street2?: string
}

export interface Contact {
  name: string
  email: string
  phone: string
}

export interface LocaleSettings {
  timezone: string
  dateFormat: string
  lengthUnits: 'cm' | 'in'
  weightUnits: 'g' | 'kg' | 'lb'
  currencyCode: string
}

export interface ApiFulfillmentLocation {
  id: string
  href?: string
}

export interface FulfillmentLocation {
  id: string
  name: string
  editable: boolean
  address?: LocationAddress
  timezone?: string
  fulfiller: Fulfiller
  isArchived?: boolean
}

export interface ServerError {
  message?: string
  errors?: ServerErrorMessage[]
  title: string
  detail?: string
}

export interface ServerErrorMessage {
  message: string
  propertyName?: string
}

export interface Fulfiller {
  fulfillerId: string
  name?: string
}

export interface ApiSequenceDefinition {
  id?: string
  description: string
  active: boolean
  current: number
  activeRange: SequenceRange
  replacementRanges: SequenceRange[]
  replacementStrategy: 'retireSequence' | 'reuseSequence'
}

export interface SequenceDefinition extends ApiSequenceDefinition {
  _metadata: {
    sequenceKey: string
    isShared: boolean
  }
}

export interface SequenceRange {
  start: number
  end: number
  prefix?: string
  suffix?: string
}

export interface PatchSequence {
  description?: string
  current?: number
  active?: boolean
  activeRange?: SequenceRange
  replacementRanges?: SequenceRange[]
  replacementStrategy?: string
}

export interface Network {
  apiNetwork: locations.models.LogisticsNetworkWithLinks
  editable: boolean
}

export interface IsHidden {
  isHidden?: boolean
}

export type Uploads = (
  | carrierAccounts.models.FtpUpload
  | carrierAccounts.models.EmailUpload
  | carrierAccounts.models.PldUpload
  | carrierAccounts.models.ElmsUpload
) &
  IsHidden

export enum GroupBy {
  Fulfillers = 'Fulfillers',
  Countries = 'Countries',
  Networks = 'Networks',
}

export interface Option {
  label: string
  value: string
}

export interface Disabled {
  isDisabled: boolean
}

export const reactSelectPortalId = 'react-select-portal'

export interface Report {
  name: string
  configurationId: string
}
export interface ReportsCustomData {
  reports: Report[]
}

export interface AuditResource {
  resourceId: string
  resourceType: audits.models.AuditResourceTypes
}

type Mutator<T> = (
  change: T | ((resource: T) => void),
  correlationId?: string
) => Promise<void>

export type LocationMutator = Mutator<locations.models.Location>
export type NetworkMutator = Mutator<locations.models.LogisticsNetwork>
